import React from "react";
import Box from "@material-ui/core/Box";
import Form from "@rjsf/material-ui";
import _ from "lodash";

function sanitize(object) {
  if (_.isString(object)) return _sanitizeString(object);
  if (_.isPlainObject(object)) return _sanitizeObject(object);
  return object;
}

function _sanitizeString(string) {
  return _.isEmpty(string) ? null : string;
}

function _sanitizeArray(array) {
  return _.filter(_.map(array, sanitize), _isProvided);
}

function _sanitizeObject(object) {
  return _.pickBy(_.mapValues(object, sanitize), _isProvided);
}

function _isProvided(value) {
  const typeIsNotSupported =
    !_.isNull(value) &&
    !_.isString(value) &&
    !_.isArray(value) &&
    !_.isPlainObject(value);
  return typeIsNotSupported || !_.isEmpty(value);
}

function FormEditor({
                        recordContent, schema, onChange = (record) => {
    }
                    }) {
    return <Box>
        {typeof (schema) !== "object" ? <Box>Please wait. Loading.</Box> :
            <Form
                formData={recordContent}
                schema={schema}
                showErrorList={false}
                onChange={({formData}) => onChange(sanitize(formData))}
                liveValidate>
                <div/>
            </Form>
        }
    </Box>;
}

export default FormEditor;
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import AddRecordPage from "./AddRecordPage";
import EditorPage from "../component/EditorPage";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import FileDownload from "js-file-download";
import axios from "axios";
import Ajv from "ajv";
import {forEach} from "react-bootstrap/ElementChildren";

class DocumentRecord {
    constructor(schema, content, valid = false) {
        this.schema = schema;
        this.content = content;
        this.valid = valid;
    }
}

const ajv = new Ajv();
ajv.addFormat(
    "data-url",
    /^data:([a-z]+\/[a-z0-9-+.]+)?;(?:name=(.*);)?base64,(.*)$/
);

function Content() {
    const [page, setPage] = useState('add-record');
    const [records, setRecords] = useState([]);
    const [schemas, setSchemas] = useState({});
    const [validators, setValidator] = useState({});
    const [selected, setSelected] = useState(-1);
    const [downloading, setDownloading] = useState("ready");
    const [reloadable, setReloadable] = useState(localStorage.getItem("lastSubmittedRecords") !== null)

    function loadSchema(url) {
        if (!schemas[url] || schemas[url] === "failed") {
            schemas[url] = "loading"
            $RefParser.dereference(url).then(result => {
                return ajv.compile(result);
            }).then(validator => {
                const additionalSchemas = {};
                additionalSchemas[url] = validator.schema
                const additionalValidators = {};
                additionalValidators[url] = validator;
                setSchemas(schemas => ({...schemas, ...additionalSchemas}))
                setValidator(validators => ({...validators, ...additionalValidators}));
            }).catch(error => {
                console.error(error);
                schemas[url] = "failed"
            });
        }
    }

    function handleReload() {
        const reloaded = JSON.parse(localStorage.getItem("lastSubmittedRecords"));
        reloaded.forEach(record => loadSchema(record.schema));
        setPage("editor");
        setRecords(reloaded);
        setSelected(0);
    }

    if (page === 'add-record' || records.length === 0) {
        return <AddRecordPage
            cancellable={records.length > 0}
            onCancel={() => setPage('editor')}
            onConfirm={
                (url) => {
                    setPage('editor');
                    setRecords([...records, new DocumentRecord(url, {})]);
                    setSelected(records.length)
                    loadSchema(url);
                }
            }
            onReload={handleReload}
            reloadable={reloadable}/>
    } else if (page === 'editor') {
        return <EditorPage
            records={records}
            schemas={schemas}
            selected={selected}
            downloading={downloading === "running"}
            onAddRecordClick={() => setPage('add-record')}
            onRecordDelete={(record, index) => {
                const modified = [...records];
                modified.splice(index, 1);
                setRecords(modified)
                if (selected === index && index === records.length - 1) {
                    setSelected(selected - 1);
                } else if (selected > index) {
                    setSelected(selected - 1);
                }
            }}
            onSelectedRecordChange={(record, index) => setSelected(index)}
            onRecordChange={(recordContent, index) => {
                const modified = [...records];
                const valid = validators[records[index].schema] && validators[records[index].schema](recordContent)
                modified[index] = new DocumentRecord(records[index].schema, recordContent, valid);
                setRecords(modified);
            }}
            onDownloadClick={() => {
                setDownloading("running");
                axios.post("/api/prospectus", records.map(record => record.content),
                    {responseType: "blob"}).then(result => {
                    FileDownload(result.data, "NCAMT_DATPRI_PRIII.zip")
                    localStorage.setItem("lastSubmittedRecords", JSON.stringify(records));
                    setReloadable(true);
                    setDownloading("ready")
                }).catch(error => {
                    console.error(error)
                    setDownloading("error")
                })
            }}
            onReload={handleReload}
            reloadable={reloadable}
        />
    } else {
        return <Box>Content page is invalid: {page}</Box>
    }
}

export default Content;
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import {Button} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReloadButton from "../component/ReloadButton";

const useStyles = makeStyles({
    root: {
        textAlign: "center",
        padding: "2em 0 0 0"
    },
    spacer: {
        marginTop: "1.4em"
    },
    gap: {
        marginRight: "1.4em"
    }
})

const AVAILABLE_SCHEMAS = [{
    url: "/schemas/single_base_prospectus.json",
    name: "Single Base Prospectus"
}, {
    url: "/schemas/single_stand_alone_prospectus.json",
    name: "Single Stand-alone Prospectus"
}, {
    url: "/schemas/tripartite_base_prospectus.json",
    name: "Tripartite Base Prospectus"
}, {
    url: "/schemas/tripartite_stand_alone_prospectus.json",
    name: "Tripartite Stand-alone Prospectus"
}, {
    url: "/schemas/amendment.json",
    name: "Amendment"
}, {
    url: "/schemas/certificate_of_approval.json",
    name: "Certificate of Approval"
}, {
    url: "/schemas/final_terms.json",
    name: "Final Terms"
}, {
    url: "/schemas/registration_document.json",
    name: "Registration Document"
}, {
    url: "/schemas/supplement_on_prospectus.json",
    name: "Supplement on Prospectus"
}, {
    url: "/schemas/supplement_on_registration.json",
    name: "Supplement on Registration Document"
}, {
    url: "/schemas/supplement_on_securities_note.json",
    name: "Supplement on Securities Note"
}, {
    url: "/schemas/translation.json",
    name: "Translation"
}];

function AddRecordPage({
                           cancellable = false, reloadable = false, onConfirm = url => {
    }, onCancel = () => {
    }, onReload = () => {}
                       }) {
    const [selected, setState] = useState(AVAILABLE_SCHEMAS[0].url);
    const classes = useStyles();
    return <Container className={classes.root}>
        <div>Select a Document Record to add to the file:</div>
        <Select className={classes.spacer} value={selected} onChange={event => setState(event.target.value)}>
            {
                AVAILABLE_SCHEMAS.map(schema => <MenuItem value={schema.url}>{schema.name}</MenuItem>)
            }
        </Select>
        <Box className={classes.spacer}>
            {cancellable ? <Button
                className={classes.gap}
                variant="contained"
                onClick={(event) => onCancel()}>Cancel</Button> : null}
            <Button
                variant="contained"
                color="primary"
                onClick={(event) => onConfirm(selected)}>Confirm</Button>
        </Box>
        <Box className={classes.spacer}>
            <ReloadButton size="medium" onClick={onReload} disabled={!reloadable}/>
        </Box>
    </Container>
}

export default AddRecordPage;
import React from "react";
import Box from "@material-ui/core/Box";

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

function CodeEditor({
                        recordContent, schema, onChange = (record) => {
    }
                    }) {
    return <Box>
        <JSONInput
            locale={locale}
            width={"100%"}
            placeholder={recordContent}
            onChange={({jsObject}) => onChange(jsObject)}/>
    </Box>;
}

export default CodeEditor;
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

function Title(props) {
    return <AppBar position="static">
        <Toolbar>
            <Typography variant="h6">
                Prospectus Tool
            </Typography>
        </Toolbar>
    </AppBar>
}

export default Title;
import React from "react";
import Box from "@material-ui/core/Box";
import Title from "../component/Title";
import Content from "./Content";

function Application() {
    return <Box>
        <Title/>
        <Content/>
        {/*<Footer />*/}
    </Box>
}

export default Application;
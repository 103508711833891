import React from "react";
import {FormGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
    toolbar: {
        padding: "0.6em 0.6em 3px 0",
        borderBottom: "1px solid black"
    }
});

function EditorSelector({
                            code = false, onChange = code => {
    }
                        }) {
    const classes = useStyles();
    return <Box display="flex" justifyContent="flex-end" className={classes.toolbar}>
        <FormGroup>
            <FormControlLabel
                control={<Switch checked={code} onChange={(event) => onChange(event.target.checked)}/>}
                label="Display Code"
            />
        </FormGroup>
    </Box>
}

export default EditorSelector;
import React from "react";
import Grid from "@material-ui/core/Grid";
import Records from "./Records";
import {Button} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DownloadButton from "./DownloadButton";
import EditorArea from "../container/EditorArea";
import ReloadButton from "./ReloadButton";

const useStyles = makeStyles({
    centered: {
        textAlign: "center"
    },
    spacer: {
        marginTop: "1.4em"
    },
});

function EditorPage({
                        records, schemas, selected,
                        downloading = false,
                        onAddRecordClick = () => {
                        },
                        onSelectedRecordChange = (record, index) => {
                        },
                        onRecordDelete = (record, index) => {
                        },
                        onRecordChange = (recordContent, index) => {
                        },
                        onDownloadClick = () => {
                        },
                        onReload = () => {},
                        reloadable = false,
                    }) {
    const classes = useStyles();
    return <Grid container spacing={2}>
        <Grid item xs={3}>
            <Records records={records} schemas={schemas} selected={selected}
                     onSelectedRecordChange={onSelectedRecordChange}
                     onRecordDelete={onRecordDelete}/>
            <Box className={classes.centered}>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Add/>}
                    onClick={() => onAddRecordClick()}> Add Record</Button>
            </Box>
            <Box className={[classes.centered, classes.spacer]}>
                <DownloadButton spinning={downloading} problematic={records.some(record => !record.valid)}
                                onClick={onDownloadClick}/>
            </Box>
            <Box className={[classes.centered, classes.spacer]}>
                <ReloadButton onClick={onReload} disabled={!reloadable} />
            </Box>
        </Grid>
        <Grid item xs={9}>
            <EditorArea
                recordContent={records[selected].content}
                schema={schemas[records[selected].schema]}
                onChange={(recordContent) => onRecordChange(recordContent, selected)}
            />
        </Grid>
    </Grid>
}

export default EditorPage;
import React from "react";
import List from "@material-ui/core/List";
import Record from "./Record";

function Records({
                     records, schemas, selected, onSelectedRecordChange = (record, index) => {
    }, onRecordDelete = (record, index) => {
    }
                 }) {
    return <List>
        {records.map((record, index) =>
            <Record
                selected={selected === index}
                problematic={!record.valid}
                onClick={() => onSelectedRecordChange(record, index)}
                onDelete={() => onRecordDelete(record, index)}
                name={typeof (schemas[record.schema]) === "object" ? schemas[record.schema].title : "Loading ..."}/>
        )}
    </List>
}

export default Records;
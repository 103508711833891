import React from "react";
import {Button} from "@material-ui/core";
import {Replay} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    wrapper: {
        position: 'relative',
    }
});

function ReloadButton({
                          disabled = false, size = "large", onClick = () => {
    }
                      }) {
    const classes = useStyles();
    return <div>
        <div className={classes.wrapper}>
            <Button disabled={disabled}
                    variant="contained"
                    color="secondary"
                    size={size}
                    startIcon={<Replay/>}
                    onClick={() => onClick()}>
                Reload
            </Button>
        </div>
    </div>
}

export default ReloadButton;
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import {Delete, Warning} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {IconButton} from "@material-ui/core";

function Record({
                    name, selected = false, problematic = false, onClick = () => {
    }, onDelete = () => {
    }
                }) {
    return <ListItem button selected={selected} onClick={(event) => onClick()}>
        <ListItemIcon>
            {problematic ? <Warning/> : null}
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
            <IconButton aria-label="delete" onClick={(event) => onDelete()}>
                <Delete/>
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
}

export default Record;
import React, {useState} from "react";
import EditorSelector from "../component/EditorSelector";
import CodeEditor from "../component/CodeEditor";
import FormEditor from "../component/FormEditor";
import Box from "@material-ui/core/Box";

function EditorArea({
                        recordContent, schema, onChange = (record) => {
    }
                    }) {
    const [{code}, setState] = useState({code: false});
    return <Box>
        <EditorSelector code={code} onChange={(code) => setState({code})}/>
        {code
            ? <CodeEditor recordContent={recordContent} schema={schema} onChange={onChange}/>
            : <FormEditor recordContent={recordContent} schema={schema} onChange={onChange}/>}
    </Box>
}

export default EditorArea;
import React from "react";
import {Button} from "@material-ui/core";
import {CloudDownload} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    verySmallText: {
        fontSize: "12px"
    }
});

function DownloadButton({
                            problematic = false, spinning = false, onClick = () => {
    }
                        }) {
    const classes = useStyles();
    return <div>
        <div className={classes.wrapper}>
            <Button disabled={spinning || problematic}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<CloudDownload/>}
                    onClick={() => onClick()}>
                Download Package
            </Button>
            {spinning && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
        {problematic &&
        <div className={classes.verySmallText}>All errors must be fixed before you can download the prospectus
            package</div>}
    </div>
}

export default DownloadButton;